import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
// @ts-ignore
import AudioPlayer from 'react-h5-audio-player';
import { pause, play, update } from '../../redux/player';

import 'react-h5-audio-player/lib/styles.css';

interface IAudio {
    url: string;
}

export function Audio({ url }: IAudio) {
    const ref = useRef<AudioPlayer>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        ref.current?.audio.current?.addEventListener('play', () => {
            dispatch(play());
        });
        ref.current?.audio.current?.addEventListener('pause', () => {
            dispatch(pause());
        });
        ref.current?.audio.current?.addEventListener('timeupdate', () => {
            dispatch(update(ref.current?.audio.current?.currentTime));
        });
    }, [dispatch]);

    return <AudioPlayer src={url} ref={ref} customVolumeControls={[]} customAdditionalControls={[]} />;
}
