import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TrackName } from '../types';

export interface PlayerState {
    currentTime: number;
    isPlaying: boolean;
    selectedTrack: TrackName | '';
}

const initialState: PlayerState = {
    currentTime: 0,
    isPlaying: false,
    selectedTrack: '',
};

export const playerSlice = createSlice({
    name: 'player',
    initialState,
    reducers: {
        update: (state, action: PayloadAction<number | undefined>) => {
            state.currentTime = action.payload || 0;
        },
        play: (state) => {
            state.isPlaying = true;
        },
        pause: (state) => {
            state.isPlaying = false;
        },
        setSelectedTrack: (state, action: PayloadAction<TrackName>) => {
            state.selectedTrack = action.payload;
        },
        removeTrack: (state) => {
            state.selectedTrack = '';
        },
    },
});

export const { update, play, pause, setSelectedTrack } = playerSlice.actions;

export default playerSlice.reducer;
