import { ILyric } from '../types';

const song: ILyric[] = [
    {
        start: 0,
        end: 4.43,
        words: 'Zero One Two Three',
    },
    {
        start: 4.44,
        end: 7.9,
        words: 'Four Five Six',
    },
    {
        start: 7.91,
        end: 12.23,
        words: 'Seven Eight Nine',
    },
];

export default song;
