import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import tracks from '../../tracks/tracks';
import { ILyric, TrackName } from '../../types';

import './Lyrics.css';

function shouldShowLyrics(currentTime: number, song: ILyric) {
    return currentTime >= song.start && currentTime <= song.end;
}

export function Lyrics({ trackName }: { trackName: TrackName }) {
    const { isPlaying, currentTime } = useSelector((state: RootState) => state.player);

    const items = tracks[trackName].map((item) => {
        const className = shouldShowLyrics(currentTime, item) ? 'show' : 'hide';
        return (
            <span key={item.start} className={`lyric-item ${className}`}>
                {item.words}
            </span>
        );
    });

    return (
        <>
            {isPlaying && ' - '}
            {isPlaying && items}
        </>
    );
}
