import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './redux/store';
import { Audio } from './components/Audio';
import { Lyrics } from './components/Lyrics';

import './App.css';
import { setSelectedTrack } from './redux/player';

function App() {
    const { isPlaying, currentTime, selectedTrack } = useSelector((state: RootState) => state.player);
    const dispatch = useDispatch();

    console.log(selectedTrack);

    const url = `tracks/${selectedTrack}.mp3`;

    return (
        <div className="App">
            <header className="App-header">
                <div style={{ minWidth: '60vw' }}>{selectedTrack && <Audio url={url} />}</div>
                <p>
                    <button onClick={() => dispatch(setSelectedTrack('one'))}>one</button>
                    <button onClick={() => dispatch(setSelectedTrack('two'))}>two</button>
                    <br />
                    <code>
                        {isPlaying ? 'playing' : 'paused'}: {currentTime.toFixed(2)}
                    </code>
                    {selectedTrack && <Lyrics trackName={selectedTrack} />}
                </p>
            </header>
        </div>
    );
}

export default App;
